import animateMenu from './modules/animateMenu';
import animateMap from './modules/animateMap';
import animateTeam from './modules/animateTeam';
import './modules/companyMap';

$(() => {
    animateMenu.init();
    animateMap.init();
    animateTeam.init();
});
