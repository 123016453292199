// Modul Code - kann in Funktionen, Klassen usw. beliebig strukturiert sein

class AnimateMenu {
    constructor($trigger, $menu, $logo, $menuButton, $menuBackground) {
        this.$trigger = $trigger;
        this.$menu = $menu;
        this.$logo = $logo;
        this.$menuButton = $menuButton;
        this.$menuBackground = $menuBackground;

        this.$trigger.on('click touch', () => this.handleClick());
    }

    handleClick() {
        this.$trigger.toggleClass('is-active');
        this.$menu.slideToggle('fast');
        this.$logo.toggleClass('is-active');
        this.$menuButton.toggleClass('is-active');
        this.$menuBackground.toggleClass('is-active');
        $('.js-navigation-mobile__background').toggleClass('is-active');
    }
}

// Dieses Objekt exponiert die API des Moduls nach Außen
// Kann auch die vollständige Funktionalität direkt beinhalten
const animateMenu = {
    init() {
        const navTrigger = $('.js-navigation-trigger');
        const navMenu = $('.js-navigation-mobile__elements');
        const navLogo = $('.js-navigation-mobile__logo');
        const navMenuButton = $('.js-navigation__element--menu');
        const navBackground = $('.js-navigation-mobile__background--dark');
        /* eslint-disable no-new */
        new AnimateMenu(navTrigger, navMenu, navLogo, navMenuButton, navBackground);
    },
};

// Export der API
export default animateMenu;
