// Modul Code - kann in Funktionen, Klassen usw. beliebig strukturiert sein

class AnimateMap {
    constructor($partnerIcons) {
        this.$highlighted = false;
        $partnerIcons.each((index, element) => {
            if (index >= $partnerIcons.length - 9) {
                $(element).addClass('partner-bottom-element');
            } else {
                $(element).addClass('partner-top-element');
            }
            $(element).click(ev => this.handleClick(ev));
        });
        this.$map = $('#map');
        this.$logoWrapper = $('.js-partner-icon-wrapper');
        $('body').click(ev => this.handleDocumentClick(ev));
    }

    handleClick(ev) {
        const $item = $(ev.currentTarget);
        if (typeof this.$activeItem === 'undefined') {
            this.$activeItem = $item;
        }

        if ($(document).width() < 576) {
            $('body').toggleClass('black-underlay');
        }

        if (!this.$highlighted) {
            ev.stopPropagation();
            this.$activeItem.removeClass('is-active');
            $item.addClass('is-active');
            this.$activeItem = $item;

            const $pin = this.$map.find(`#${$item.data('location')}`);

            if (typeof this.$activePin === 'undefined') {
                this.$activePin = $pin;
            }

            this.$activePin.removeClass('is-active');
            this.$activePin = $pin;

            // Innerhalb einer SVG gibt es keinen z-index sondern es wird nach Painters Algorithm gezeichnet
            // Um ein Element hervorzuheben wird es jeweils am Ende des SVGs eingezeichnet
            document.querySelector('#location-pins').appendChild($pin[0]);

            this.$logoWrapper.addClass('is-active');
            this.$highlighted = true;

            setTimeout(() => $(this.$activePin[0]).addClass('is-active'), 100);
        } else {
            this.$activeItem.removeClass('is-active');
            this.$activePin.removeClass('is-active');
            this.$logoWrapper.removeClass('is-active');
            this.$highlighted = false;
        }
    }

    handleDocumentClick() {
        if (this.$highlighted) {
            this.$activeItem.removeClass('is-active');
            this.$activePin.removeClass('is-active');
            this.$logoWrapper.removeClass('is-active');
            this.$highlighted = false;
            $('body').removeClass('black-underlay');
        }
    }
}

// Dieses Objekt exponiert die API des Moduls nach Außen
// Kann auch die vollständige Funktionalität direkt beinhalten
const AnimateMapExport = {
    init() {
        const $partnerIcons = $('.js-partner-icon');
        /* eslint-disable no-new */
        new AnimateMap($partnerIcons);
    },
};

// Export der API
export default AnimateMapExport;
