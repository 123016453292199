// Modul Code - kann in Funktionen, Klassen usw. beliebig strukturiert sein

class AnimateTeam {
    constructor($photos) {
        $photos.each((index, element) => {
            $(element).click((ev) => {
                const $item = $(ev.currentTarget);
                $item.toggleClass('active');
            });
        });
    }
}

// Dieses Objekt exponiert die API des Moduls nach Außen
// Kann auch die vollständige Funktionalität direkt beinhalten
const animateTeam = {
    init() {
        /* eslint-disable no-new */
        new AnimateTeam($('.js-team-overlay'));
    },
};

// Export der API
export default animateTeam;
